import css from 'styled-jsx/css';
import { desktop, phone, phone_tablet } from '../../../styles/media';

export const getTooltipStyles = () => css.resolve`
  div {
    padding: 7px 15px;
    border-radius: 15.5px;
    font-size: 12px;
    line-height: 17px;
    white-space: nowrap;
    background: var(--colors-site);
    opacity: 1 !important;
  }
  div:after {
    border-top-color: var(--colors-site);
    opacity: 1 !important;
  }
`;

export const getNumberInputResolve = (
  isBig = false,
  isLow = false,
  inputMargin,
) => {
  const inputWidth = isBig ? 160 : 48;
  const inputHeight = isLow ? 32 : 40;

  return css.resolve`
    input {
      width: ${inputWidth}px;
      flex-grow: 1;
      height: ${inputHeight}px;
      padding: 10px;
      margin: ${inputMargin || 0};
      border-width: 1px;
      border-style: solid;
      outline: none;
      text-align: center;
      box-sizing: border-box;
      color: var(--colors-site);
      border-color: var(--colors-brandLightGrey2);
      border-radius: var(--border-radius-roundBox);
    }
    input:hover {
      border-color: var(--colors-brandLightGrey8);
    }
    input:focus {
      border-color: var(--colors-doveGray);
    }
    @media ${phone} {
      .QuantityBox_mobileExtend input {
        position: absolute;
        width: 100%;
      }
    }

    @media ${desktop} {
      input {
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  `;
};

export const styles = css`
  .QuantityBox {
    display: flex;
    justify-content: center;
    max-width: 100%;
    outline: none;
    position: relative;
  }

  .QuantityBox__tooltip {
    position: absolute;
    width: 100%;
  }

  .QuantityBox__deleteButton {
    color: var(--uiColors-QuantityBox-deleteButtonIcon);
    font-size: 12px;
  }
  .QuantityBox__button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    outline: none;
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: var(--border-radius-roundBox);
    background-color: var(--colors-white);
    border: 1px solid var(--uiColors-QuantityBox-buttonBorder);
    transition: 0.2s background ease-in-out, 0.2s border-color ease-in-out;
  }

  .QuantityBox__button:active {
    background: var(--uiColors-QuantityBox-buttonActiveBackground);
    border-color: var(--uiColors-QuantityBox-buttonActiveBorder);
  }

  .QuantityBox__button:active .QuantityBox__deleteButton {
    color: var(--uiColors-QuantityBox-deleteButtonHoverIcon);
    transition: 0.2s color ease-in-out;
  }

  .QuantityBox__button_plus {
    background-color: var(--uiColors-QuantityBox-buttonPlusBackground);
  }

  .QuantityBox__button_plus svg rect {
    fill: var(--uiColors-QuantityBox-plusButtonIconFill);
  }

  .QuantityBox__button_plus:active {
    background-color: var(--uiColors-QuantityBox-buttonPlusBackgroundActive);
    border-color: var(--uiColors-QuantityBox-buttonPlusBackgroundActive);
  }

  .QuantityBox__button_minus svg rect {
    transition: 0.2s fill ease-in-out;
    fill: var(--colors-site);
  }

  .QuantityBox__button_minus:active svg rect {
    fill: var(--uiColors-QuantityBox-minusButtonHoverIconFill);
  }
  .QuantityBox_small {
    width: 150px;
  }
  .QuantityBox_small .QuantityBox__field {
    width: 70px;
    height: 32px;
    margin: 0 10px;
    -webkit-appearance: none;
  }
  .QuantityBox_small .QuantityBox__button {
    width: 32px;
    height: 32px;
  }
  .QuantityBox_small .QuantityBox__button_minus svg {
    top: 13px;
    left: 10px;
  }
  .QuantityBox_small .QuantityBox__button_plus svg {
    top: 9px;
    left: 9px;
  }

  @media ${desktop} {
    .QuantityBox__button:hover {
      background: var(--uiColors-QuantityBox-buttonHoverBackground);
      border-color: var(--uiColors-QuantityBox-buttonHoverBorder);
    }

    .QuantityBox__button:hover .QuantityBox__deleteButton {
      color: var(--uiColors-QuantityBox-deleteButtonHoverIcon);
      transition: 0.2s color ease-in-out;
    }

    .QuantityBox__button_plus:hover {
      background-color: var(--uiColors-QuantityBox-buttonPlusBackgroundHover);
      border-color: var(--uiColors-QuantityBox-buttonPlusBackgroundHover);
    }

    .QuantityBox__button_minus:hover svg rect {
      fill: var(--uiColors-QuantityBox-minusButtonHoverIconFill);
    }
  }
  @media ${phone} {
    .QuantityBox_small {
      margin: 0 auto;
    }
  }
  @media ${phone_tablet} {
    .QuantityBox_mobileExtend {
      position: relative;
      width: 100%;
      height: 40px;
    }
    .QuantityBox_mobileExtend .QuantityBox__button {
      position: absolute;
    }
    .QuantityBox_mobileExtend .QuantityBox__button_minus {
      z-index: 2;
      left: 0;
    }
    .QuantityBox_mobileExtend .QuantityBox__button_plus {
      z-index: 2;
      right: 0;
    }
  }
`;
